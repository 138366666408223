import { render, staticRenderFns } from "./CkTextInput.vue?vue&type=template&id=220f8afe&"
import script from "./CkTextInput.vue?vue&type=script&lang=js&"
export * from "./CkTextInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/LondonBoroughSutton/sutton-information-hub-v2-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('220f8afe')) {
      api.createRecord('220f8afe', component.options)
    } else {
      api.reload('220f8afe', component.options)
    }
    module.hot.accept("./CkTextInput.vue?vue&type=template&id=220f8afe&", function () {
      api.rerender('220f8afe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Ck/CkTextInput.vue"
export default component.exports